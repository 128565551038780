<template>
  <div>
    <b-container class="base-container-x contact-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-bind:class="{ 'd-none': !video }" class="col-12 mb-5 pt-4">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 mb-3">
            <div class="player video-player">
            <vue-plyr :options="options" ref="player">
              <video></video>
            </vue-plyr>
            </div>
              <div class="video-content pt-3" v-if="video">
                <h1 class="video-title">{{ video.name.substring(0, 255) }}</h1>
                <p class="video-date title">{{ new Date(video.created_at) | dateFormat('MMM DD, YYYY') }} <span class="video-date float-right">{{ video.viewer | numeral('0,0') }} views</span></p>
              </div>
              <hr class="col-12"/>
              <div class="row">
                 <div class="col-1">
                    <img width="48" rounded="circle" src="../../assets/images/logo/logo.png">
                  </div>
                  <div class="col-11 my-auto">
                    <p class="video-author ml-4 ml-lg-3 my-auto">WYA Yoga</p>
                  </div>
              </div>
              <div class="row" v-if="video">
                  <div class="col-1">
                  </div>
                  <div class="col-11">
                    <p class="video-desc ml-4 ml-lg-3">{{ video.description }}</p>
                  </div>
              </div>
              <hr class="col-12"/>
            </div>
             <div class="col-12 col-md-12 col-lg-4">
                <div v-for="video in videos" :key="video.id">
                  <VideoRelatedItem mode="exclusive" :item="video"/>
                </div>
            </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script src="//vjs.zencdn.net/7.10.2/video.min.js"></script>
<script>
import VuePlyr from 'vue-plyr'
import Hls from 'hls.js'
import VideoRelatedItem from './video/VideoRelatedItem'
import Api from '../../api/api'

export default {
  name: 'exclusiveCenterVideo',
  title: ' | Exclusive Center Video',
  components: {
    VideoRelatedItem,
    VuePlyr
  },
  data () {
    return {
      loading: true,
      videoId: null,
      video: null,
      isMemberSchool: true,
      isMemberTeacher: true,
      options: {
        autoplay: false,
        ratio: '16:9',
        loop: { active: false }
      },
      videos: []
    }
  },
  computed: {
    player () {
      return this.$refs.player.player;
    },
    userProfile () {
      return this.$store.getters.userProfile
    },
    schoolId () {
      return this.$store.getters.schoolId
    },
    teacherId () {
      return this.$store.getters.teacherId
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.getVideoDetail(id)
    },
    isMemberTeacher (newValue, oldValue) {
      if (!this.isMemberSchool && !this.isMemberTeacher) {
        this.$router.push({ name: 'exclusiveCenter' })
      }
    },
    isMemberSchool (newValue, oldValue) {
      if (!this.isMemberSchool && !this.isMemberTeacher) {
        this.$router.push({ name: 'exclusiveCenter' })
      }
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.videoId = this.$route.params.id
    if (!this.userProfile || !(this.schoolId || this.teacherId)) {
      this.$router.push({ name: 'exclusiveCenter' })
    } else {
      this.getMySchool()
      this.getTeacher()
    }
    this.getVideoDetail(this.videoId)
    this.getVideo()
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getVideoDetail (id) {
      Api.videoDetail({id: id}).then(response => {
        if (response.data) {
          this.video = response.data
          if (Hls.isSupported()) {
            const hls = new Hls()
            hls.loadSource(response.data.url_stream)
            hls.attachMedia(this.player.media)
            window.hls = hls
            setTimeout(()=> { document.querySelector('.plyr__control--overlaid').click() }, 1000)
          }
        }
        this.loading = false
      }).catch((error) => {
        this.loading = false
      })
    },
    getVideo () {
      Api.video({limit: 10, is_public: 0, ordering:'-created_at'}).then(response => {
        if (response.data) {
          this.videos = response.data.results
        } else {
          this.videos = []
        }
      }).catch(() => {
        this.videos = []
      })
    },
    getMySchool () {
      this.$store.dispatch('getMySchool', { user: this.userProfile.id })
        .then((response) => {
          if (response.data.results.length == 0 || response.data.results[0].status != 2) {
            this.isMemberSchool = false
          }
          this.loading = false
        })
    },
    getTeacher () {
      this.$store.dispatch('getTeacher', { user: this.userProfile.id })
        .then((response) => {
          if (response.data.results.length == 0 || response.data.results[0].status != 2) {
            this.isMemberTeacher = false
          }
          this.loading = false
        })
    },
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  hr.solid {
    border-top: 3px solid #bbb;
  }
  .video-author{
    font-size: 14px;
    font-weight: bold;
  }
  .video-desc{
    font-size: 14px;
  }
  .video-title {
    font-size: 18px;
    color: #000;
  }
  .video-date {
    font-size: 14px;
    color: gray;
  }
  @media(max-width: 768px) {
    .video-player {
    position: fixed;
    width: 100%;
    top: 70px;
    left: 0px;
    padding: 0px;
    padding-top: 40px;
    z-index: 1;
    background-color: #fff;
    }
    .video-content {
      margin-top: 440px
    }
  }
  @media(max-width: 576px) {
    .video-player {
    position: fixed;
    width: 100%;
    top: 70px;
    left: 0px;
    padding: 0px;
    padding-top: 40px;
    z-index: 1;
    background-color: #fff;
    }
    .video-content {
      margin-top: 240px
    }
  }
</style>
